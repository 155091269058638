import type { FC } from 'react'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RANGES } from 'src/components/composed/LoanProductCalc/constants'
import { messages } from 'src/components/composed/LoanProductCalc/messages'
import { pushGtmEventOnChangeCalculate } from '@/components/composed/LoanProductCalc/gtm-helpers'
import { declension } from '@/components/composed/LoanProductCalc/helpers'
import { RangeSlider } from '@/components/ui/ReactHookFormFields'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import { EPriceCurrency } from '@/types'

const mapState = (state: RootState) => ({
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const LoanProductCalcForm: FC = () => {
  const { control } = useFormContext()
  const { formatNumber } = useIntl()

  const { userGtmInfo, userRegion } = useSelector(mapState)

  const formatSum = (value: number) => `${formatNumber(value)} ${EPriceCurrency.RUB}`
  const formatDay = (value: number) => `${declension(Number(formatNumber(value)))} `

  return (
    <div className="loanProductCalcForm">
      <div className="loanProductCalcForm__fields">
        <RangeSlider
          control={control}
          label={messages.form.size}
          name="sum"
          sliderProps={RANGES.sum}
          formatValue={formatNumber}
          inputEnding={EPriceCurrency.RUB}
          formatLabel={formatSum}
          onChange={value => pushGtmEventOnChangeCalculate(userGtmInfo, userRegion.name, `${messages.gtmMessages.sum}-${value}`)}
        />

        <RangeSlider
          control={control}
          label={messages.form.term}
          name="days"
          sliderProps={RANGES.days}
          formatValue={formatNumber}
          formatLabel={formatDay}
          onChange={value => pushGtmEventOnChangeCalculate(userGtmInfo, userRegion.name, `${messages.gtmMessages.days}-${value}`)}
        />
      </div>
    </div>
  )
}
