import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { useCreate } from '@/api/kubik/credit'
import { LoanProductConsultForm } from '@/pages/Financing/components/FinancingProducts/components/LoanProducts/components/LoanProductsList/components/LoanProduct/components/LoanProductCollapsing/LoanProductConsult/components/LoanProductConsultForm'
import { navItems } from '@/pages/FinancingAgent/constants'
import { LoanProductOrderError } from '../LoanProductOrderError'
import { LoanProductOrderMethodCommunication } from '../LoanProductOrderMethodCommunication'
import { LoanProductOrderSuccess } from '../LoanProductOrderSuccess'
import { ELoanProductConsultStep } from './constants'
import type { ILoanProductConsultProps } from './types'
import './components/LoanProductConsultForm/styles.scss'

export const LoanProductConsult: FC<ILoanProductConsultProps> = props => {
  const { product, onBack } = props
  const [stepConsult, setStepConsult] = useState<ELoanProductConsultStep>(ELoanProductConsultStep.FORM)
  const [successFundingRequestId, setSuccessFundingRequestId] = useState<number | undefined>()

  const setDefaultStep = useCallback(() => setStepConsult(ELoanProductConsultStep.FORM), [])
  const setSuccessStep = useCallback(() => setStepConsult(ELoanProductConsultStep.SUCCESS), [])
  const setErrorStep = useCallback(() => setStepConsult(ELoanProductConsultStep.ERROR), [])

  const { mutateAsync: createFundingRequest, isLoading } = useCreate({
    mutation: {
      onSuccess: data => {
        setSuccessFundingRequestId(data.data.fundingRequests?.[0]?.id)
        setStepConsult(ELoanProductConsultStep.COMMUNICATION_METHOD)
      },
      onError: () => setStepConsult(ELoanProductConsultStep.ERROR),
    },
  })

  const activeStep = () => {
    switch (stepConsult) {
      case ELoanProductConsultStep.COMMUNICATION_METHOD:
        if (successFundingRequestId) {
          return (
            <LoanProductOrderMethodCommunication
              fundingRequestId={successFundingRequestId}
              onSuccessUpdateFundingRequest={setSuccessStep}
              onErrorUpdateFundingRequest={setErrorStep}
            />
          )
        }

        return null

      case ELoanProductConsultStep.SUCCESS:
        return <LoanProductOrderSuccess navItems={navItems} resetStep={setDefaultStep} fundingRequestId={successFundingRequestId} />
      case ELoanProductConsultStep.ERROR:
        return <LoanProductOrderError onResetStep={setDefaultStep} />
      default:
        return (
          <LoanProductConsultForm product={product} onBack={onBack} onCreateConsultRequest={createFundingRequest} isLoading={isLoading} />
        )
    }
  }

  return <div className="loanProductConsult">{activeStep()}</div>
}
