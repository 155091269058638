import type { ICommonLinkProps } from '@frontend/pole-ui/lib/components/CommonLink'
import type { TLandingDetailsInformative } from '@/components/composed/landingBlocks/LandingDetailsInformative/types'
import type { TLandingFAQItem } from '@/components/composed/landingBlocks/LandingFAQ/types'
import type { TLandingStep } from '@/components/composed/landingBlocks/LandingSteps/types'
import type { IBreadcrumb } from '@/components/ui/Breadcrumbs/Breadcrumb/interfaces'
import { financingPageBreadcrumb } from '@/components/ui/Breadcrumbs/constants'
import { EGtmLabel } from '@/logic/metrika/financing/types'
import { EAgriculturalProductsRoute, EFinancingOfferRoute, EHarvestSaleRoute, EJournalRoute } from '@/types'
import type { TFormatOptions } from '@/utils/formatUtils'
import { TextWithConsultAction } from './components/TextWithConsultAction'
import { messages } from './messages'

export const BREADCRUMB: IBreadcrumb = {
  caption: messages.breadcrumbs.currentPage,
  link: EFinancingOfferRoute.MFOPage,
}

export const PARTNER_IMAGES = ['pole_rf']
export const ROOT_BREADCRUMBS = [financingPageBreadcrumb]

export const LANDING_DETAILS: Readonly<TLandingDetailsInformative[]> = [
  {
    icon: 'financingOffer/agent/benefits/ruble.png',
    value: messages.details.list.reward.value,
    label: messages.details.list.reward.label,
    size: 'Big',
  },
  {
    icon: 'financingOffer/agent/benefits/jackdawGear.png',
    value: messages.details.list.approval.value,
    label: messages.details.list.approval.label,
    size: 'Small',
  },
  {
    icon: 'financingOffer/agent/benefits/jackdawCalendar.png',
    value: messages.details.list.pay.value,
    label: messages.details.list.pay.label,
    size: 'Small',
  },
  {
    icon: 'financingOffer/agent/benefits/bagOfMoney.png',
    value: messages.details.list.income.value,
    label: messages.details.list.income.label,
    size: 'Small',
  },
  {
    icon: 'financingOffer/agent/benefits/bagWithMan.png',
    value: messages.details.list.education.value,
    label: messages.details.list.education.label,
    size: 'Small',
  },
  {
    icon: 'financingOffer/agent/benefits/cards.png',
    value: messages.details.list.status.value,
    label: messages.details.list.status.label,
    size: 'Small',
  },
  {
    icon: 'financingOffer/agent/benefits/manWithJackdaw.png',
    value: messages.details.list.available.value,
    label: messages.details.list.available.label,
    size: 'Small',
  },
]

export const LANDING_INTRESTING_DETAILS: Readonly<TLandingDetailsInformative[]> = [
  {
    icon: 'financingOffer/agent/intresting/wheatCoin.png',
    value: messages.intresting_details.list.repayment.value,
    size: 'Big',
  },
  {
    icon: 'financingOffer/agent/intresting/jackdawDocuments.png',
    value: messages.intresting_details.list.registration.value,
    size: 'Small',
  },
  {
    icon: 'financingOffer/agent/intresting/coinRuble.png',
    value: messages.intresting_details.list.refinancing.value,
    size: 'Small',
  },
  {
    icon: 'financingOffer/agent/intresting/bank.png',

    value: messages.intresting_details.list.crediting.value,
    size: 'Small',
  },
  {
    icon: 'financingOffer/agent/intresting/darts.png',
    value: messages.intresting_details.list.approval.value,
    size: 'Small',
  },
  {
    icon: 'financingOffer/agent/intresting/jackdawCalendarGold.png',
    value: messages.intresting_details.list.early_repayment.value,
    size: 'Big',
  },
]

export const LANDING_STEPS: TLandingStep[] = [
  {
    title: messages.steps[1].title,
  },
  {
    title: messages.steps[2].title,
  },
  {
    title: messages.steps[3].title,
  },
]

export const LANDING_FAQ: TLandingFAQItem[] = [
  {
    question: messages.faq.questions.howBeAgent.title,
    answer: messages.faq.questions.howBeAgent.answer,
  },
  {
    question: messages.faq.questions.whoCanBeAgent.title,
    answer: messages.faq.questions.whoCanBeAgent.answer,
  },
  {
    question: messages.faq.questions.paymentOfRemuneration.title,
    answer: messages.faq.questions.paymentOfRemuneration.answer,
  },
  {
    question: messages.faq.questions.minQuantity.title,
    answer: messages.faq.questions.minQuantity.answer,
  },
  {
    question: messages.faq.questions.trackResults.title,
    answer: messages.faq.questions.trackResults.answer,
  },
  {
    question: messages.faq.questions.speciaeEducation.title,
    answer: messages.faq.questions.speciaeEducation.answer,
  },
]

export const FAQ_LINK_FORMAT_OPTIONS: Omit<ICommonLinkProps, 'url'> = {
  className: 'color_green underline underline_green',
}

export const FAQ_FORMAT_OPTIONS: Readonly<TFormatOptions> = {
  OpenConsultingForm: TextWithConsultAction,
}

export const navItems = [
  {
    title: 'Агентская программа от ПолеФинанс',
    image: 'coin',
    link: EFinancingOfferRoute.FinancingAgentPage,
    gtmLabel: EGtmLabel.OUTPUTS,
  },
  {
    title: 'Заявка на продажу\nурожая',
    image: 'coin_wheat',
    link: EHarvestSaleRoute.main,
    gtmLabel: EGtmLabel.OUTPUTS,
  },
  {
    title: 'Купить товары',
    image: 'cart',
    link: EAgriculturalProductsRoute.main,
    gtmLabel: EGtmLabel.INPUTS,
  },
  {
    title: 'Новости сельского\nхозяйства',
    image: 'book',
    link: EJournalRoute.MAIN,
    gtmLabel: EGtmLabel.JOURNAL,
  },
]
