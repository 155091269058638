import type { FC } from 'react'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Faq } from '@/components/composed'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { pushGtmEventOnExpandFaq } from '@/pages/Financing/components/FinancingFaq/gtm-helpers'
import type { RootState } from '@/redux/interfaces'
import { EAgriculturalProductsRoute } from '../../../../types'
import { messages } from './messages'

const mapState = (state: RootState) => ({
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

const FinancingFaq: FC = () => {
  const { pushGtmExpandFaq } = useGtm()
  const { userGtmInfo, userRegion } = useSelector(mapState)

  const items = [
    {
      question: messages.item1.question,
      answer: (
        <>
          {messages.item1.answer.p}
          <ul>
            <li>{messages.item1.answer.li1}</li>
            <li>{messages.item1.answer.li2}</li>
            <li>{messages.item1.answer.li3}</li>
            <li>{messages.item1.answer.li4}</li>
          </ul>
        </>
      ),
    },
    {
      question: messages.item2.question,
      answer: (
        <>
          {messages.item2.answer.p}
          <ul>
            <li>{messages.item2.answer.li1}</li>
            <li>{messages.item2.answer.li2}</li>
            <li>{messages.item2.answer.li3}</li>
            <li>{messages.item2.answer.li4}</li>
            <li>{messages.item2.answer.li5}</li>
            <li>{messages.item2.answer.li6}</li>
            <li>{messages.item2.answer.li7}</li>
          </ul>
        </>
      ),
    },
    {
      question: messages.item3.question,
      answer: (
        <>
          <div>
            <CommonLink url={EAgriculturalProductsRoute.main}>{messages.item3.answer.p1.anchor}</CommonLink>
            {messages.item3.answer.p1.text}
          </div>
          <ul>
            <li>{messages.item3.answer.li1}</li>
            <li>{messages.item3.answer.li2}</li>
            <li>{messages.item3.answer.li3}</li>
            <li>{messages.item3.answer.li4}</li>
            <li>{messages.item3.answer.li5}</li>
            <li>{messages.item3.answer.li6}</li>
          </ul>
        </>
      ),
    },
  ]

  return (
    <Faq
      title={messages.title}
      items={items}
      onExpand={question => {
        pushGtmExpandFaq(question)
        pushGtmEventOnExpandFaq(userGtmInfo, userRegion.name, question)
      }}
    />
  )
}

export default memo(FinancingFaq)
