export const declension = (num: number) => {
  const pr = new Intl.PluralRules('ru-RU')

  const forms: Record<string, string> = {
    one: 'день',
    few: 'дня',
    many: 'дней',
    other: 'дней',
  }
  const rule = pr.select(num) as string

  return `${num} ${forms[rule]}`
}
