import React from 'react'
import { useHistory } from 'react-router-dom'
import NavSuccessNew from '@/components/composed/landingBlocks/LandingApplyingOfferFormNew/components/ApplyingFinancingAgentSuccessNew/components/NavSuccessNew/NavSuccessNew'
import { Button, GlassMorphIcon } from '@/components/ui'
import { EUserProfileRoute } from '@/types'
import { createRouterLink } from '@/utils/url'
import { messages } from './messages'
import type { ILoanProductOrderSuccessProps } from './types'
import './styles.scss'

export const LoanProductOrderSuccess: React.FC<ILoanProductOrderSuccessProps> = props => {
  const { resetStep, fundingRequestId, navItems } = props

  const history = useHistory()

  const onClickToOrderPage = () => history.push(createRouterLink(EUserProfileRoute.fundingRequest, { id: String(fundingRequestId) }))

  return (
    <div className="loanProductOrderSuccess">
      <div className="loanProductOrderSuccess__wrapper">
        <div className="loanProductOrderSuccess__header">
          <GlassMorphIcon image="success" className="loanProductOrderSuccess__icon" />

          <h3 className="loanProductOrderSuccess__title">{messages.title}</h3>
          <div className="loanProductOrderSuccess__subtitle">{messages.subtitle}</div>
        </div>

        <div className="loanProductOrderSuccess__main">
          <NavSuccessNew classNameIcon="loanProductOrderSuccess__icon-size" navItems={navItems} />
        </div>
        <div className="loanProductOrderSuccess__footer">
          <div className="loanProductOrderSuccess__actions">
            <Button modifiers={['outline-gray', 'full-width-mobile']} onClick={resetStep}>
              {messages.actions.close}
            </Button>
            <Button modifiers={['green', 'full-width-mobile']} onClick={onClickToOrderPage}>
              {messages.actions.goToOrder}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
