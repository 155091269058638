import type { TMessages } from '@/types/TMessages'

export const messages = {
  title: 'Предпочтительный способ связи',
  fields: {
    communicationMethod: {
      phone: 'Звонок \n по телефону',
      whatsapp: 'Сообщение \nв WhatsApp',
    },
  },
  actions: {
    select: 'Выбрать',
  },
} satisfies TMessages
