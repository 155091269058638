import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import debounce from 'lodash/debounce'
import type { CalculateMutationRequest } from '@/api/kubik/credit'
import { useCalculateMfo } from '@/api/kubik/credit'
import { GlassMorphIcon } from '@/components/ui'
import { MFOCalculatorTerms } from '@/pages/MFOPage/components/MFOCalculatorSection/components/MFOCalculator/components/MFOCalculatorTerms'
import { DEBOUNCE_DELAY } from './constants'
import { messages } from './messages'

export const LoanProductCalcResult: FC = () => {
  const { watch } = useFormContext()

  const { data, mutateAsync: calculate, isError } = useCalculateMfo()

  const [sumValue, daysValue] = watch(['sum', 'days'])

  const debouncedRequest = useMemo(
    () =>
      debounce(async (payload: CalculateMutationRequest) => {
        await calculate({ ...payload })
      }, DEBOUNCE_DELAY),
    [calculate],
  )

  useEffect(() => {
    debouncedRequest({ expressMfoLoanTerm: daysValue, expressMfoAmount: sumValue })
  }, [daysValue, debouncedRequest, sumValue])

  const specialOfferEconomy = data?.data.data?.expressMfoEconomy ?? null
  const specialOfferOverpayment = data?.data.data?.expressMfoPercentAmount ?? null
  const standardOverpayment = data?.data.data?.marketAverageAmount ?? null

  return (
    <div className="loanProductCalcResult">
      {isError ? (
        <div className="loanProductCalcResultError">
          <GlassMorphIcon image="attention-green" />
          <div className="loanProductCalcResultError__message">{messages.error.message}</div>
        </div>
      ) : (
        <MFOCalculatorTerms
          standardOverpayment={standardOverpayment}
          standardEconomy={0}
          specialOfferOverpayment={specialOfferOverpayment}
          specialOfferEconomy={specialOfferEconomy}
        />
      )}
    </div>
  )
}
