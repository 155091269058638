import type { FC } from 'react'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { INITIAL_FORM_VALUES } from 'src/components/composed/LoanProductCalc/constants'
import { LoanProductCalcForm } from 'src/components/composed/LoanProductCalc/LoanProductCalcForm'
import { LoanProductCalcPs } from 'src/components/composed/LoanProductCalc/LoanProductCalcPs'
import { LoanProductCalcResult } from 'src/components/composed/LoanProductCalc/LoanProductCalcResult'
import { messages } from 'src/components/composed/LoanProductCalc/messages'
import type { ILoanProductCalcProps } from 'src/components/composed/LoanProductCalc/types'
import LoanProductCalcActions from '@/components/composed/LoanProductCalc/LoanProductCalcActions'
import 'src/components/composed/LoanProductCalc/styles.scss'

export const LoanProductCalc: FC<ILoanProductCalcProps> = props => {
  const { isShowTitle = true, actionOnOffer, actionOnConsult } = props

  const { ...formMethod } = useForm({ defaultValues: INITIAL_FORM_VALUES })

  return (
    <div className="loanProductCalc">
      {isShowTitle && <h3 className="loanProductCalc__title">{messages.title}</h3>}

      <div className="loanProductCalcWrap">
        <FormProvider {...formMethod}>
          <div className="loanProductCalcWrap__form">
            <LoanProductCalcForm />
            <LoanProductCalcResult />
          </div>
          <LoanProductCalcActions actionOnConsult={actionOnConsult} actionOnOffer={actionOnOffer} />
          <LoanProductCalcPs />
        </FormProvider>
      </div>
    </div>
  )
}
