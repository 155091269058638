import type { FC } from 'react'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@/components/ui'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { EGtmLabel, EGtmProductName } from '@/logic/metrika/financing/types'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { pushGtmEventOnConsult } from '@/pages/Financing/components/FinancingQuestions/gtm-helpers'
import type { RootState } from '@/redux/interfaces'
import { scrollToTop } from '@/utils/scroll'
import { messages } from './messages'
import './styles.scss'

const mapState = (state: RootState) => ({
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

const FinancingQuestions: FC = () => {
  const { pushGtmProduct } = useGtm()
  const { userGtmInfo, userRegion } = useSelector(mapState)

  const onClick = () => {
    pushGtmProduct(EGtmProductName.MARKETPLACE, EGtmLabel.ACTION)
    pushGtmEventOnConsult(userGtmInfo, userRegion.name)
    scrollToTop()
  }

  return (
    <div className="financingQuestions">
      <h3 className="financingQuestions__title">{messages.title}</h3>
      <div className="financingQuestions__description">{messages.description}</div>
      <div className="financingQuestions__button">
        <Button modifiers={['green', 'full-width-mobile']} onClick={onClick}>
          {messages.button}
        </Button>
      </div>
    </div>
  )
}

export default memo(FinancingQuestions)
