import React from 'react'
import { useForm } from 'react-hook-form'
import classnames from 'classnames'
import { useUpdateFundingRequest } from '@/api/kubik/credit'
import { Button, Image } from '@/components/ui'
import { RadioButton } from '@/components/ui/ReactHookFormFields'
import { ECommunicationMethod } from './constants'
import { messages } from './messages'
import type { ILoanProductOrderMethodCommunicationProps } from './types'
import './styles.scss'

export const LoanProductOrderMethodCommunication: React.FC<ILoanProductOrderMethodCommunicationProps> = props => {
  const { fundingRequestId, onSuccessUpdateFundingRequest, onErrorUpdateFundingRequest } = props

  const { control, getValues, watch } = useForm({
    defaultValues: {
      communicationMethod: ECommunicationMethod.PHONE,
    },
  })

  const communicationMethodField = watch('communicationMethod')

  const { mutateAsync: updateFundingRequest, isLoading } = useUpdateFundingRequest({
    mutation: { onSuccess: onSuccessUpdateFundingRequest, onError: onErrorUpdateFundingRequest },
  })

  const onSelectCommunicationMethod = async () => {
    await updateFundingRequest({ fundingRequestId, communicationMethod: getValues('communicationMethod') })
  }

  return (
    <div className="loanProductOrderMethodCommunication">
      <h3 className="loanProductOrderMethodCommunication__title">{messages.title}</h3>

      <div className="loanProductOrderMethodCommunication__container">
        <div
          className={classnames('loanProductOrderMethodCommunication__card', {
            loanProductOrderMethodCommunication__card__selected: communicationMethodField === ECommunicationMethod.PHONE,
          })}
        >
          <Image className="loanProductOrderMethodCommunication__image" src="financingOffer/agent/connections/phone.png" isInStatic />
          <RadioButton
            className="loanProductOrderMethodCommunication__radio-button"
            name="communicationMethod"
            control={control}
            value={ECommunicationMethod.PHONE}
            CustomLabel={<h5>{messages.fields.communicationMethod.phone}</h5>}
          />
        </div>
        <div
          className={classnames('loanProductOrderMethodCommunication__card', {
            loanProductOrderMethodCommunication__card__selected: communicationMethodField === ECommunicationMethod.WHATSAPP,
          })}
        >
          <Image className="loanProductOrderMethodCommunication__image" src="financingOffer/agent/connections/whatsapp.png" isInStatic />
          <RadioButton
            className="loanProductOrderMethodCommunication__radio-button"
            name="communicationMethod"
            control={control}
            value={ECommunicationMethod.WHATSAPP}
            CustomLabel={<h5>{messages.fields.communicationMethod.whatsapp}</h5>}
          />
        </div>
      </div>

      <div className="loanProductOrderMethodCommunication__actions">
        <Button type="submit" modifiers={['green']} loaderProps={{ isVisible: isLoading }} onClick={onSelectCommunicationMethod}>
          {messages.actions.select}
        </Button>
      </div>
    </div>
  )
}
