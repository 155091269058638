import React from 'react'
import cn from 'classnames'
import { EMFOScrollEventBlockId } from '@/pages/FinancingAgent/gtmConstants'
import { asyncScrollIntoView } from '@/utils/helpers'
import { FAQ_LINK_FORMAT_OPTIONS } from '../../constants'
import './styles.scss'

export const TextWithConsultAction = (text: string) => {
  const handleClickSubmitApplication = () => {
    asyncScrollIntoView(`#${EMFOScrollEventBlockId.form}`)
  }

  return (
    <span onClick={() => handleClickSubmitApplication()} className={cn(FAQ_LINK_FORMAT_OPTIONS.className, 'textWithConsultAction__link')}>
      {text}
    </span>
  )
}
