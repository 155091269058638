import React from 'react'
import { Button } from '@/components/ui'
import { messages } from './messages'
import type { ILoanProductCalcActionsProps } from './types'
import './styles.scss'

const LoanProductCalcActions: React.FC<ILoanProductCalcActionsProps> = props => {
  const { actionOnConsult, actionOnOffer } = props

  return (
    <div className="loanProductCalcActions">
      <Button onClick={actionOnConsult} modifiers={['outline', 'full-width-mobile']}>
        {messages.result.actions.requestConsult}
      </Button>
      <Button onClick={actionOnOffer} modifiers={['green', 'full-width-mobile']}>
        {messages.result.actions.sentOrder}
      </Button>
    </div>
  )
}

export default LoanProductCalcActions
