import type { FC } from 'react'
import React from 'react'
import { messages } from './messages'

const psList = [
  {
    symbol: '*',
    message: messages.ps,
  },
  {
    symbol: '**',
    message: messages.ps2,
  },
]

export const LoanProductCalcPs: FC = () => {
  return (
    <div className="loanProductCalcPs">
      {psList.map(psItem => (
        <div className="loanProductCalcPs__item" key={psItem.message}>
          <div>{psItem.symbol}</div>
          <div>{psItem.message}</div>
        </div>
      ))}
    </div>
  )
}
