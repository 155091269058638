import React from 'react'
import { useIntl } from 'react-intl'
import { isNonNullable } from '@/typeguards/common'
import { EPriceCurrency } from '@/types'
import { formatMoney } from '@/utils/price'
import { messages } from './messages'
import type { TMFOCalculatorPriceProps } from './types'

export const MFOCalculatorPrice = ({ value }: TMFOCalculatorPriceProps) => {
  const intl = useIntl()

  if (isNonNullable(value)) {
    return (
      <>
        {formatMoney(value, intl)}&nbsp;{EPriceCurrency.RUB}
      </>
    )
  }

  return <>{messages.noValue}</>
}
