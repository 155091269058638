import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import omit from 'lodash/omit'
import type { CreateFundingRequestPrivateDto } from '@/api/kubik/credit'
import type { IApplyingFinancingOfferRequestBody } from '@/logic/financing/interfaces'
import type { EFinancingProductId } from '@/types'
import { MFO_PRODUCTS } from './constants'
import { messages } from './messages'
import type { TFinancingOfferModalStepsAdapter } from './types'

export const defaultFinancingOfferStepsAdapter: TFinancingOfferModalStepsAdapter = (steps, context, onClickConsult, isLoading = false) =>
  steps.map(step => ({
    ...step,
    fields: step.fields.map(field => {
      const controlledField = { ...field }

      if (field.fieldType === 'checkbox' || field.fieldType === 'policyAgreement')
        return omit(controlledField, ['activeControlStyle', 'borderColorStyle', 'markColor'])

      if (field.fieldType === 'infoText')
        return {
          ...field,
          className: 'loanProductOrderForm__infoText',
          iconProps: {
            name: EIconName.InfoFill,
            className: 'loanProductOrderForm__infoIcon',
            style: { color: EColor.GREEN },
          },
        }

      if (field.name === 'loanAmount.value') return { ...controlledField, shouldBeTouchedBeforeError: true }

      return controlledField
    }),
    calcButton: {
      modifiers: ['outline-gray', 'full-width-mobile'],
      textId: messages.actions.requestConsult,
      onClick: onClickConsult,
    },
    submitButton: {
      ...step.submitButton,
      modifiers: ['green', 'full-width-mobile'],
      loaderProps: {
        isVisible: isLoading,
      },
    },
  }))

export const formattedRequestBody = (formValues: IApplyingFinancingOfferRequestBody): CreateFundingRequestPrivateDto => {
  return {
    phone: formValues.phone,
    email: formValues.email,
    agreement: formValues.agreement,
    city: formValues.city,
    comment: formValues.comment,
    loanAmount: formValues.loanAmount,
    productIds: [formValues.productId as string],
    fiasCode: formValues.fiasCode,
    cropsArea: formValues.cropsArea,
    regionCode: formValues.regionCode,
    grossHarvest: formValues.grossHarvest,
    contactPersonPosition: formValues.contactPersonPosition,
    desiredAdvancePaymentDate: formValues.desiredAdvancePaymentDate,
  }
}

export const checkAvailableCalculateMfo = (productId: EFinancingProductId) => MFO_PRODUCTS.includes(productId)
