import type { TMessages } from '@/types/TMessages'

export const messages = {
  terms: {
    standardTerms: 'Стандартные условия**',
    top10: 'Топ-10 банков РФ',
    fromTop10: 'От Топ-10 банков РФ',
    specialOfferForAgribusiness: 'Специально для агробизнеса',
    item: {
      repaymentProcedure: {
        label: 'Порядок погашения',
        standardTerms: 'Ежемесячно',
        specialOfferForAgribusiness: {
          value: 'Без ежемесячных платежей',
          additionalValue: 'в конце срока',
        },
      },
      deadline: {
        label: 'Скорость выдачи',
        standardTerms: '≈ 1 месяц',
        specialOfferForAgribusiness: 'до 5 рабочих дней',
      },
      overpayment: {
        label: 'Сумма переплаты',
      },
      economy: {
        label: 'Ваша экономия',
        tooltip: 'Экономия = сумма среднерыночной переплаты - сумма переплаты по займу от <Link>поле.рф</Link>',
      },
    },
    noValue: '-',
    showAllParams: 'Показать все параметры',
    hideAllParams: 'Скрыть все параметры',
    forAgro: 'Специально для агробизнеса',
  },
} satisfies TMessages
