import type { FC } from 'react'
import React, { useState } from 'react'
import { LoanProductCalc } from '@/components/composed/LoanProductCalc'
import type { EFinancingProductId } from '@/types'
import { ELoanProductCollapsing, LOAN_PRODUCTS_HAS_ONLY_CONSULT_FORM } from './constants'
import { LoanProductConsult } from './LoanProductConsult'
import { LoanProductOrder } from './LoanProductOrder'
import type { ILoanProductCollapsingProps } from './types'
import './style.scss'

export const LoanProductCollapsing: FC<ILoanProductCollapsingProps> = props => {
  const { product, navItems } = props

  const [isActiveBlock, setIsActiveBlock] = useState(
    LOAN_PRODUCTS_HAS_ONLY_CONSULT_FORM.includes(product.productId as EFinancingProductId)
      ? ELoanProductCollapsing.CONSULT
      : ELoanProductCollapsing.ORDER,
  )

  const onClickOfferButton = () => setIsActiveBlock(ELoanProductCollapsing.ORDER)
  const onClickConsult = () => setIsActiveBlock(ELoanProductCollapsing.CONSULT)
  const onClickBackFromConsult = () => setIsActiveBlock(ELoanProductCollapsing.ORDER)

  const activeBlock = () => {
    switch (isActiveBlock) {
      case ELoanProductCollapsing.CALC:
        return <LoanProductCalc actionOnOffer={onClickOfferButton} actionOnConsult={onClickConsult} />
      case ELoanProductCollapsing.CONSULT:
        return <LoanProductConsult navItems={navItems} onBack={onClickBackFromConsult} product={product} />
      case ELoanProductCollapsing.ORDER:
      default:
        return <LoanProductOrder product={product} setIsActiveBlock={setIsActiveBlock} />
    }
  }

  return <div className="loanProductCollapsing">{activeBlock()}</div>
}
