import type { TMessages } from '@/types/TMessages'

export const messages = {
  title: 'Заявка отправлена',
  subtitle: 'Ожидайте обратную связь по заявке в течение рабочего дня',
  actions: {
    close: 'Закрыть',
    goToOrder: 'К заявке',
  },
} satisfies TMessages
