import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { useCreate } from '@/api/kubik/credit'
import { navItems } from '@/pages/FinancingAgent/constants'
import { LoanProductOrderError } from '../LoanProductOrderError'
import { LoanProductOrderMethodCommunication } from '../LoanProductOrderMethodCommunication'
import { LoanProductOrderSuccess } from '../LoanProductOrderSuccess'
import { LoanProductOrderForm } from './components/LoanProductOrderForm'
import { ELoanProductOrderStep } from './constants'
import type { ILoanProductOrderProps } from './types'

export const LoanProductOrder: FC<ILoanProductOrderProps> = props => {
  const { product, setIsActiveBlock } = props
  const [stepOrder, setStepOrder] = useState<ELoanProductOrderStep>(ELoanProductOrderStep.FORM)
  const [successFundingRequestId, setSuccessFundingRequestId] = useState<number | undefined>()

  const setDefaultStep = useCallback(() => setStepOrder(ELoanProductOrderStep.FORM), [])

  const setSuccessStep = useCallback(() => setStepOrder(ELoanProductOrderStep.SUCCESS), [])
  const setErrorStep = useCallback(() => setStepOrder(ELoanProductOrderStep.ERROR), [])

  const { mutateAsync: createFundingRequest, isLoading } = useCreate({
    mutation: {
      onSuccess: data => {
        setSuccessFundingRequestId(data.data.fundingRequests?.[0]?.id)
        setStepOrder(ELoanProductOrderStep.COMMUNICATION_METHOD)
      },
      onError: () => setStepOrder(ELoanProductOrderStep.ERROR),
    },
  })

  const activeStep = () => {
    switch (stepOrder) {
      case ELoanProductOrderStep.COMMUNICATION_METHOD:
        if (successFundingRequestId) {
          return (
            <LoanProductOrderMethodCommunication
              fundingRequestId={successFundingRequestId}
              onSuccessUpdateFundingRequest={setSuccessStep}
              onErrorUpdateFundingRequest={setErrorStep}
            />
          )
        }

        return null
      case ELoanProductOrderStep.SUCCESS:
        return <LoanProductOrderSuccess navItems={navItems} resetStep={setDefaultStep} fundingRequestId={successFundingRequestId} />
      case ELoanProductOrderStep.ERROR:
        return <LoanProductOrderError onResetStep={setDefaultStep} />
      default:
        return (
          <LoanProductOrderForm
            product={product}
            setIsActiveBlock={setIsActiveBlock}
            createFundingRequest={createFundingRequest}
            isLoading={isLoading}
          />
        )
    }
  }

  return <div className="loanProductOrder">{activeStep()}</div>
}
