import qs from 'qs'
import type { ViewByOptionsQueryParams } from '@/api/kubik/credit'
import { formKnownValues } from '@/pages/Financing/components/FinancingProducts/components/LoanProducts/components/LoanProductsFilter/constants'
import type { IQueryParams } from '@/pages/OfferRequests/hooks/useQueryParams' // todo: "кража" типа с другой страницы
import { messages } from './messages'
import type { ILoanProductsFilterForm, ILoanProductsFilterQueryParams } from './types'

export const sumFormatter = (showCurrency = false) =>
  new Intl.NumberFormat('ru-RU', {
    ...(showCurrency && { style: 'currency', currency: 'RUB' }),
    maximumSignificantDigits: 5,
    signDisplay: 'never',
  })

export const formatTerm = (value: number) => `${value} ${messages.shortMonth}`

export const convertFormValuesToQueryString = (formValues: ILoanProductsFilterForm, defaultValues: ILoanProductsFilterForm): string => {
  const stringFormValues = Object.keys(formValues).reduce<Record<string, string>>((accum, key) => {
    const item = formValues[key as keyof typeof formValues]

    if (item === undefined || item === defaultValues[key as keyof typeof defaultValues]) {
      return accum
    }

    if (Array.isArray(item) && item.length < 1) {
      return accum
    }

    accum[key] = JSON.stringify(item)

    return accum
  }, {})

  const currentParams = qs.parse(window.location.search.substring(1), { duplicates: 'last' }) as Record<string, string>

  Object.keys(currentParams).forEach(key => {
    if (formKnownValues.has(key)) {
      delete currentParams[key]
    }
  })

  const searchParams = new URLSearchParams({ ...currentParams, ...stringFormValues })

  if ([...searchParams.keys()].length > 0) {
    searchParams.set('noscrolltop', 'true')
  }

  return searchParams.toString()
}

export const convertQueryParamsToFormValues = (queryParams: ILoanProductsFilterQueryParams): Partial<ILoanProductsFilterForm> => {
  return Object.keys(queryParams).reduce((accum, key) => {
    if (!formKnownValues.has(key)) {
      return accum
    }

    accum[key] = JSON.parse(queryParams[key as keyof ILoanProductsFilterQueryParams]!)

    return accum
  }, {} as ILoanProductsFilterForm)
}

export const convertFormValuesToReactQuery = (values: ILoanProductsFilterForm) => {
  return Object.keys(values).reduce<IQueryParams>((accum, key) => {
    const item = values[key as keyof typeof values]

    if (!formKnownValues.has(key)) {
      return accum
    }

    if (!item) {
      return accum
    }

    if (Array.isArray(item) && item.length > 0) {
      accum[key as keyof ViewByOptionsQueryParams] = item.join(',').toUpperCase()
    } else {
      accum[key] = values[key] as string
    }

    return accum
  }, {})
}
