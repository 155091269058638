import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import { createConsultingRequestBody } from '@/components/composed/ConsultingForm/utils'
import { ConsultingFormRegistrationRules } from '@/components/composed/finansingConsulting/ConsultingFormRegistrationRules'
import { Button } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { TextInput } from '@/components/ui/ReactHookFormFields'
import { getUser } from '@/logic/auth/reducer'
import { LOAN_PRODUCTS_HAS_ONLY_CONSULT_FORM } from '@/pages/Financing/components/FinancingProducts/components/LoanProducts/components/LoanProductsList/components/LoanProduct/components/LoanProductCollapsing/constants'
import type { RootState } from '@/redux/interfaces'
import { EFinancingOfferType, EFinancingProductId } from '@/types'
import { normalizePhoneNumber } from '@/utils/formatUtils'
import { isApplyingOfferRequestBody } from '@/utils/hooks/applyingFinancingOffer/typeguard'
import { useApplyingFinancingOfferForm } from '@/utils/hooks/applyingFinancingOffer/useApplyingFinancingOfferForm'
import { messages } from './messages'
import type { IConsultingFormValues, ILoanProductConsultFormProps } from './types'
import './styles.scss'

const mapState = (state: RootState) => ({
  user: getUser()(state),
})

export const LoanProductConsultForm: FC<ILoanProductConsultFormProps> = props => {
  const { onBack, product, onCreateConsultRequest, isLoading } = props

  const { user } = useSelector(mapState)

  const { setOptions, profile } = useApplyingFinancingOfferForm({
    productId: EFinancingProductId.MARKETPLACE,
    offerType: EFinancingOfferType.MARKETPLACE,
  })

  const initialValues: IConsultingFormValues = {
    loanAmount: '',
    purpose: '',
    phone: profile.phone,
  }

  const { control, handleSubmit, setValue } = useForm<IConsultingFormValues>({ defaultValues: initialValues })
  const { isAuthenticated, openAuthModal } = useAuthenticateUser()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setValue('phone', normalizePhoneNumber(profile.phone)), [profile.phone])

  const onSubmit = handleSubmit(async (values: IConsultingFormValues) => {
    if (isAuthenticated) {
      const body = createConsultingRequestBody({ values, user, productId: product.productId as EFinancingProductId })

      if (isApplyingOfferRequestBody(body)) {
        await onCreateConsultRequest({
          agreement: body.agreement,
          comment: body.comment,
          email: body.email,
          loanAmount: body.loanAmount,
          phone: body.phone,
          productIds: [EFinancingProductId.MARKETPLACE],
        })
      }
    } else {
      openAuthModal({
        shouldSendCodeImmediately: true,
        shouldSkipSelectRoleStep: true,
        initialValues: { phone: normalizePhoneNumber(values.phone) },
        onCloseDialog: () =>
          setOptions({ formValues: createConsultingRequestBody({ values, user, productId: product.productId as EFinancingProductId }) }),
      })
    }
  })

  return (
    <div className="loanProductConsultForm">
      <form onSubmit={onSubmit}>
        <div className="loanProductConsultFormContent">
          <h3 className="loanProductConsultFormContent__title">{messages.title}</h3>
          <p>{messages.description}</p>

          <div className="loanProductConsultFormFields">
            <TextInput
              name="phone"
              type="tel"
              placeholder={messages.form.phone.placeholder}
              rules={{ required: messages.form.errors.required }}
              control={control}
              shouldBeTouchedBeforeError={false}
              isRequired
            />
          </div>

          <ConsultingFormRegistrationRules />
        </div>

        <div className="loanProductConsultFormActions">
          <Button
            isHidden={LOAN_PRODUCTS_HAS_ONLY_CONSULT_FORM.includes(product.productId as EFinancingProductId)}
            onClick={onBack}
            modifiers={['outline-gray']}
            iconPosition="left"
            iconProps={{ name: EIconName.ShevronLeft }}
          >
            {messages.actions.back}
          </Button>
          <Button type="submit" modifiers={['green']} loaderProps={{ isVisible: isLoading }}>
            {messages.actions.submit}
          </Button>
        </div>
      </form>
    </div>
  )
}
