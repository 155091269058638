import type { FC } from 'react'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Image } from '@/components/ui'
import { pushGtmEventOnClickApplyOnFundingOfferCard } from '@/logic/metrika/financing/gtmHelpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { LOAN_PRODUCTS } from '@/pages/Financing/components/FinancingProducts/components/LoanProducts/constants'
import { navItems } from '@/pages/FinancingAgent/constants'
import type { RootState } from '@/redux/interfaces'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { LoanProductAdditionalValues } from './components/LoanProductAdditionalValues'
import { LoanProductCollapsing } from './components/LoanProductCollapsing'
import { LoanProductConditions, LoanProductFooter, LoanProductHeader } from './components'
import { ProductInfoContext } from './constants'
import type { ILoanProductProps } from './types'
import './styles.scss'

const mapState = (state: RootState) => ({
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

const LoanProduct: FC<ILoanProductProps> = props => {
  const { product, activeCollapsingProductId, setActiveCollapsingProductId, isLeasing, isAccentCard = false } = props
  const { isMobile } = useDeviceType()
  const { userGtmInfo, userRegion } = useSelector(mapState)

  const productInfo = LOAN_PRODUCTS[product.productId]

  if (!product || !productInfo) return null

  const isShowCollapsingBlock = activeCollapsingProductId === product.productId

  return (
    <ProductInfoContext.Provider value={productInfo}>
      <div className={classNames('loanProduct', { 'loanProduct--accent': isAccentCard })}>
        <div className={classNames('loanProduct__wrapper', { 'loanProduct--active': isShowCollapsingBlock })}>
          <div className="loanProduct__anchor" id={product.productId} />
          <div className="loanProduct__data">
            {isAccentCard && (
              <Image className="loanProduct__farmerChoice" src={`financing/farmersChoice${isMobile ? 'Mobile' : ''}.svg`} isInStatic />
            )}
            <LoanProductHeader title={product.title} isShowFarmerChoice={isAccentCard} />
            <LoanProductConditions conditions={product.detailCards} />
            <LoanProductAdditionalValues additionalValues={product.additionalCards} />
            <LoanProductFooter
              product={product}
              isShowCollapsingBlock={isShowCollapsingBlock}
              setIsShowCollapsingBlock={setActiveCollapsingProductId}
              isLeasing={isLeasing}
              onPushGtmEventActionMore={() =>
                pushGtmEventOnClickApplyOnFundingOfferCard(userGtmInfo, userRegion.name, product.title, 'details_tab_click')
              }
              onPushGtmEventActionOrder={() =>
                pushGtmEventOnClickApplyOnFundingOfferCard(userGtmInfo, userRegion.name, product.title, 'tab_click')
              }
            />
          </div>
        </div>
        {isShowCollapsingBlock && <LoanProductCollapsing navItems={navItems} product={product} />}
      </div>
    </ProductInfoContext.Provider>
  )
}

export default memo(LoanProduct)
