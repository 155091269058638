import type { FC } from 'react'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { useCreateLost } from '@/api/kubik/credit'
import { CustomizedForm } from '@/components/composed/forms'
import { useApplyingOfferFormSteps } from '@/components/composed/landingBlocks/deprecated/LandingApplyingOfferForm/useApplyingOfferFormSteps'
import { addModalToWellKnown } from '@/components/composed/Modal/constants'
import { FinancingConsultingModal } from '@/components/composed/modals'
import { Button, Icon } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { FormValuesFormatter, requestStatusName } from '@/constants/financing'
import { isUserAgrarian } from '@/logic/auth/reducer'
import type { IFinancingOfferFormValues } from '@/logic/financing/interfaces'
import { pushGtmEventOnClickConsult, pushGtmEventOnRouteAuthFromFormOrder, useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { ELoanProductCollapsing } from '@/pages/Financing/components/FinancingProducts/components/LoanProducts/components/LoanProductsList/components/LoanProduct/components/LoanProductCollapsing/constants'
import { ProductInfoContext } from '@/pages/Financing/components/FinancingProducts/components/LoanProducts/components/LoanProductsList/components/LoanProduct/constants'
import type { RootState } from '@/redux/interfaces'
import type { EFinancingProductId } from '@/types'
import { getApplyingFinancingOfferFormInitialValues } from '@/utils/getApplyingFinancingOfferFormInitialValues'
import { isApplyingOfferRequestBody } from '@/utils/hooks/applyingFinancingOffer/typeguard'
import { useApplyingFinancingOfferForm } from '@/utils/hooks/applyingFinancingOffer/useApplyingFinancingOfferForm'
import type { TFormInteractionHandler } from '@/utils/hooks/applyingFinancingOffer/useOpenApplyingFinancingOfferModal/types'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { checkAvailableCalculateMfo, defaultFinancingOfferStepsAdapter, formattedRequestBody } from './helpers'
import { messages } from './messages'
import type { ILoanProductOrderFormProps } from './types'
import './styles.scss'

addModalToWellKnown({ FinancingConsultingModal })

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const LoanProductOrderForm: FC<ILoanProductOrderFormProps> = props => {
  const { product, setIsActiveBlock, createFundingRequest, isLoading } = props
  const { isMobile } = useDeviceType()

  const { isAgrarian, userGtmInfo, userRegion } = useSelector(mapState)

  const productInfo = useContext(ProductInfoContext)!

  const productId = product.productId as EFinancingProductId
  const offerType = productInfo.offerType

  const { pushGtmFormInteraction } = useGtm()

  const onFormInteraction: TFormInteractionHandler = eventLabel => {
    if (productInfo.gtmId && eventLabel) pushGtmFormInteraction(productInfo.gtmId, eventLabel)
  }

  const { isAuthenticated, openAuthModal } = useAuthenticateUser()
  const steps = useApplyingOfferFormSteps(offerType, isAuthenticated)
  const { dispatch, setOptions, formValues: values, profile } = useApplyingFinancingOfferForm({ productId, offerType })

  const { mutateAsync: createFundingRequestLost } = useCreateLost()

  const isShowCalcButton = checkAvailableCalculateMfo(productId)

  const shouldDisableButton = isAuthenticated && !isAgrarian

  const openConsultForm = () => {
    pushGtmEventOnClickConsult(userGtmInfo, userRegion.name, product.title)
    setIsActiveBlock(ELoanProductCollapsing.CONSULT)
  }

  const formattedSteps = useMemo(
    () => {
      const offerTypeRelatedSteps = defaultFinancingOfferStepsAdapter(steps, { dispatch, onFormInteraction }, openConsultForm, isLoading)

      return offerTypeRelatedSteps
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [steps],
  )

  const formattedStepsRef = useRef(formattedSteps)

  useEffect(() => {
    formattedStepsRef.current = formattedSteps
  }, [formattedSteps])

  const onSubmitForm = async (formValues: IFinancingOfferFormValues) => {
    const formattedFormValues = FormValuesFormatter[offerType]?.(formValues) || formValues

    if (isAuthenticated) {
      if (isApplyingOfferRequestBody(formattedFormValues)) {
        await createFundingRequest(formattedRequestBody(formattedFormValues))
      }
    } else {
      setOptions({ formValues: {} })
      pushGtmEventOnRouteAuthFromFormOrder(userGtmInfo, userRegion.name, null, productId)

      openAuthModal({
        shouldSendCodeImmediately: true,
        initialValues: { phone: formValues.phone, email: formValues.email, role: 'user' },
        onCloseDialog: () => {
          setOptions({ formValues })
        },
        onCloseModal: async () => {
          if (isApplyingOfferRequestBody(formattedFormValues)) {
            await createFundingRequestLost(formattedRequestBody(formattedFormValues))
          }
        },
        shouldSkipSelectRoleStep: true,
      })
    }
  }

  return (
    <div className="loanProductOrderForm">
      <h3 className="loanProductOrderForm__title">{messages.title}</h3>
      <div className="loanProductOrderForm__row">
        <div className="loanProductOrderFormInfo">
          <p>{messages.subtitle}</p>

          {isShowCalcButton && (
            <div className="loanProductOrderFormCalc">
              <p className="loanProductOrderFormCalc__heading">{messages.calc.heading}</p>
              <Button
                className={classNames('loanProductOrderCalc__action', { button_small: isMobile })}
                modifiers={['outline-gray', 'full-width-mobile']}
                iconProps={{
                  name: EIconName.ShevronRight,
                  className: 'loanProductFooter__actionsIcon',
                }}
                iconPosition="right"
                onClick={() => setIsActiveBlock(ELoanProductCollapsing.CALC)}
              >
                <Icon className="loanProductOrderFormCalc__actionIcon" name={EIconName.Calculator} />
                {messages.calc.action}
              </Button>
            </div>
          )}
        </div>

        <div className="loanProductOrderFormFields">
          <CustomizedForm<IFinancingOfferFormValues>
            initialValues={getApplyingFinancingOfferFormInitialValues({ offerType, productId, profile, formValues: values })}
            onSubmit={onSubmitForm}
            steps={formattedStepsRef.current}
            requestStatusName={requestStatusName}
            shouldDisableButton={shouldDisableButton}
            shouldShowRequiringFieldsLabel={false}
          />
        </div>
      </div>
    </div>
  )
}
