import React from 'react'
import { Button, GlassMorphIcon } from '@/components/ui'
import { messages } from './messages'
import type { ILoanProductOrderErrorProps } from './types'
import './styles.scss'

export const LoanProductOrderError: React.FC<ILoanProductOrderErrorProps> = props => {
  const { onResetStep } = props

  return (
    <div className="loanProductOrderError">
      <GlassMorphIcon image="red/info" className="loanProductOrderError__icon" />

      <h4 className="loanProductOrderError__title">{messages.title}</h4>
      <div className="loanProductOrderError__subtitle">{messages.subtitle}</div>
      <div className="loanProductOrderError__actions">
        <Button modifiers={['green']} onClick={onResetStep}>
          {messages.actions.done}
        </Button>
      </div>
    </div>
  )
}
