import type { TMessages } from '@/types/TMessages'

export const messages = {
  title: 'Кредитный калькулятор',
  form: { size: 'Предполагаемый размер займа', term: 'Срок займа', formatDay: 'день' },
  psSymbol: '*',
  ps: 'Предложение носит информационный характер и не является публичной офертой по ст.437 (2) ГК РФ. Все приведенные расчеты являются предварительными. Для получения точных условий обратитесь, пожалуйста, к менеджерам',
  ps2: 'Условия собраны на основе исследования условий по кредитам от ТОП-10 крупнейших банков РФ',
  result: {
    actions: {
      sentOrder: 'Подать заявку',
      requestConsult: 'Получить консультацию',
    },
  },
  error: {
    message: 'Пожалуйста попробуйте рассчитать кредит с другими параметрами',
  },
  gtmMessages: {
    sum: 'Размер',
    days: 'Cрок',
  },
} satisfies TMessages
