import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import type { TNavSuccessNewProps } from '@/components/composed/landingBlocks/LandingApplyingOfferFormNew/components/ApplyingFinancingAgentSuccessNew/components/NavSuccessNew/types'
import { Image } from '@/components/ui'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import type { EGtmLabel } from '@/logic/metrika/financing/types'
import { useWebpExtension } from '@/utils/hooks/image'
import './styles.scss'

function NavSuccessNew({ navItems, classNameIcon }: TNavSuccessNewProps) {
  const imageExtension = useWebpExtension()
  const { pushGtmSuccessfulModal } = useGtm()

  const onNavItemClick = (label: EGtmLabel) => {
    pushGtmSuccessfulModal(label)
  }

  return (
    <div className="navSuccessNew">
      {navItems?.map(item => (
        <CommonLink
          className={`navSuccessNewItem ${item.link === '/financingOffer/agent' ? 'navSuccessNewItem__agent' : ''}`}
          url={item.link}
          key={item.link}
          onClick={() => onNavItemClick(item.gtmLabel)}
          exact
        >
          <div className="navSuccessNew Item__image">
            <Image
              src={`financingOffer/agent/modal/${item.image}.${imageExtension}`}
              className={classNames('navSuccessNewItem__icon', classNameIcon)}
              alt={item.title}
              isInStatic
            />
          </div>
          <h5 className="navSuccessNewItem__title text_small">
            <span>{item.title}</span> <Icon name={EIconName.ShevronRight} className="endTimer__icon" />
          </h5>
        </CommonLink>
      ))}
    </div>
  )
}

export default NavSuccessNew
