import type { IUserGtmInfoPayload } from '@/interfaces'
import type { IBaseGtmEventFinancing } from '@/logic/metrika/financing/types'
import { pushGtmEvent } from '@/logic/metrika/helpers'

export const pushGtmEventOnChangeFilter = (userGtmInfo: IUserGtmInfoPayload, userRegionName: string, eventContext: Maybe<string>) => {
  pushGtmEvent<IBaseGtmEventFinancing>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'click',
    blockName: 'blockFinance',
    eventLabel: 'filter_click',
    eventContext,
    ...userGtmInfo,
  })
}
