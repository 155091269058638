import React, { useState } from 'react'
import { BubblingTooltip } from '@frontend/pole-ui/lib/components/BubblingTooltip'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classnames from 'classnames'
import { Button, Image, TextWithIcon } from '@/components/ui'
import { ECommonRoute } from '@/types'
import { formatLocalizedString } from '@/utils/formatUtils'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { MFOCalculatorPrice } from './components/MFOCalculatorPrice'
import { messages } from './messages'
import type { TMFOCalculatorTermsProps } from './types'
import './styles.scss'

export const MFOCalculatorTerms = ({
  className,
  standardOverpayment,
  standardEconomy,
  specialOfferEconomy,
  specialOfferOverpayment,
}: TMFOCalculatorTermsProps) => {
  const { isMobile } = useDeviceType()
  const [isShowMobileContent, setIsShowMobileContent] = useState(true)

  const onShowMobileContent = () => setIsShowMobileContent(!isShowMobileContent)

  return (
    <div className={classnames('MFOCalculatorTerms', className)}>
      <div className="MFOCalculatorTerms__desktop">
        <div className="MFOCalculatorTerms__container">
          <div className="MFOCalculatorTerms__standard ">
            <div className="MFOCalculatorTerms__header">
              <h3 className="MFOCalculatorTerms__standardTermsTitle h4-sm">{messages.terms.standardTerms}</h3>
              <div className="MFOCalculatorTerms__fromTop10 text_super-small-sm">{messages.terms.fromTop10}</div>
            </div>
            <div className="MFOCalculatorTerms__terms">
              <div className="MFOCalculatorTerms__term MFOCalculatorTerms__term--repaymentProcedure">
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.repaymentProcedure.label}</div>
                <div className="h4 h5-sm">{messages.terms.item.repaymentProcedure.standardTerms}</div>
              </div>
              <div className="MFOCalculatorTerms__term MFOCalculatorTerms__term--deadline">
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.deadline.label}</div>
                <div className="h4 h5-sm">{messages.terms.item.deadline.standardTerms}</div>
              </div>
              <div className="MFOCalculatorTerms__term">
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.overpayment.label}</div>
                <div className="h4 h5-sm">
                  <MFOCalculatorPrice value={standardOverpayment} />
                </div>
              </div>
              <div className="MFOCalculatorTerms__term MFOCalculatorTerms__term--economy">
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.economy.label}</div>
                <div className="h3 h4-sm MFOCalculatorTerms__economyValue">
                  <MFOCalculatorPrice value={standardEconomy} />
                </div>
              </div>
            </div>
          </div>
          <div className="MFOCalculatorTerms__special">
            <div className="MFOCalculatorTerms__header">
              <Image className="MFOCalculatorTerms__specialImage" src="financingOffer/mfo/logo.svg" isInStatic />
              <div className="MFOCalculatorTerms__specialForAgribusiness">
                <TextWithIcon
                  iconProps={{ name: EIconName.Sell, size: EIconSize.M }}
                  textModifier="base"
                  textClassName="text_super-small-sm"
                  text={messages.terms.specialOfferForAgribusiness}
                />
              </div>
            </div>
            <div className="MFOCalculatorTerms__terms">
              <div className="MFOCalculatorTerms__term MFOCalculatorTerms__term--repaymentProcedure">
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.repaymentProcedure.label}</div>
                <div>
                  <div className="h4 h5-sm">{messages.terms.item.repaymentProcedure.specialOfferForAgribusiness.value}</div>
                  <div className="MFOCalculatorTerms__label text_small text_super-small-sm">
                    {messages.terms.item.repaymentProcedure.specialOfferForAgribusiness.additionalValue}
                  </div>
                </div>
              </div>
              <div className="MFOCalculatorTerms__term MFOCalculatorTerms__term--deadline">
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.deadline.label}</div>
                <div className="h4 h5-sm">{messages.terms.item.deadline.specialOfferForAgribusiness}</div>
              </div>
              <div className="MFOCalculatorTerms__term">
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.overpayment.label}</div>
                <div className="h4 h5-sm">
                  <MFOCalculatorPrice value={specialOfferOverpayment} />
                </div>
              </div>

              <div className="MFOCalculatorTerms__term MFOCalculatorTerms__term--economy">
                <div className="MFOCalculatorTerms__label MFOCalculatorTerms__label--accent text_super-small-sm">
                  {messages.terms.item.economy.label}
                </div>
                <div className="h3 h4-sm MFOCalculatorTerms__economyValue">
                  <MFOCalculatorPrice value={specialOfferEconomy} />
                  <div>
                    <BubblingTooltip
                      content={formatLocalizedString(messages.terms.item.economy.tooltip, {
                        Link: text => (
                          <CommonLink className="color_green underline underline_green" url={ECommonRoute.main}>
                            {text}
                          </CommonLink>
                        ),
                      })}
                      tooltipProps={{
                        touch: true,
                        interactive: true,
                        hideOnClick: false,
                        zIndex: 2,
                        className: 'MFOCalculatorTerms__tooltip-position',
                        placement: isMobile ? 'top-end' : 'top',
                      }}
                    >
                      <div>
                        <Icon className="MFOCalculatorTerms__questionIcon" name={EIconName.Question} size={EIconSize.M} />
                      </div>
                    </BubblingTooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="MFOCalculatorTerms__mobile">
        <div className="MFOCalculatorTerms__container">
          <div className="MFOCalculatorTerms__standard">
            <h3 className="MFOCalculatorTerms__standardTermsTitle">{messages.terms.top10}</h3>

            <div className="MFOCalculatorTerms__terms">
              <div
                className={classnames('MFOCalculatorTerms__term MFOCalculatorTerms__term--repaymentProcedure', {
                  MFOCalculatorTerms__hideContent: isShowMobileContent,
                })}
              >
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.repaymentProcedure.label}</div>
                <div className="h4 h5-sm">{messages.terms.item.repaymentProcedure.standardTerms}</div>
              </div>
              <div
                className={classnames('MFOCalculatorTerms__term MFOCalculatorTerms__term--deadline', {
                  MFOCalculatorTerms__hideContent: isShowMobileContent,
                })}
              >
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.deadline.label}</div>
                <div className="h4 h5-sm">{messages.terms.item.deadline.standardTerms}</div>
              </div>
              <div className="MFOCalculatorTerms__term">
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.overpayment.label}</div>
                <div className="h4 h5-sm">
                  <MFOCalculatorPrice value={standardOverpayment} />
                </div>
              </div>
              <div className="MFOCalculatorTerms__term MFOCalculatorTerms__term--economy">
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.economy.label}</div>
                <div className="h3 h4-sm MFOCalculatorTerms__economyValue">
                  <MFOCalculatorPrice value={standardEconomy} />
                </div>
              </div>

              <b className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.standardTerms}</b>
            </div>
          </div>
          <div className="MFOCalculatorTerms__special">
            <Image className="MFOCalculatorTerms__specialImage" src="financingOffer/mfo/logo.svg" isInStatic />

            <div className="MFOCalculatorTerms__terms">
              <div
                className={classnames('MFOCalculatorTerms__term MFOCalculatorTerms__term--repaymentProcedure', {
                  MFOCalculatorTerms__hideContent: isShowMobileContent,
                })}
              >
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.repaymentProcedure.label}</div>
                <div>
                  <div className="h4 h5-sm">{messages.terms.item.repaymentProcedure.specialOfferForAgribusiness.value}</div>
                  <div className="MFOCalculatorTerms__label text_small text_super-small-sm">
                    {messages.terms.item.repaymentProcedure.specialOfferForAgribusiness.additionalValue}
                  </div>
                </div>
              </div>
              <div
                className={classnames('MFOCalculatorTerms__term MFOCalculatorTerms__term--deadline', {
                  MFOCalculatorTerms__hideContent: isShowMobileContent,
                })}
              >
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.deadline.label}</div>
                <div className="h4 h5-sm">{messages.terms.item.deadline.specialOfferForAgribusiness}</div>
              </div>

              <div className="MFOCalculatorTerms__term">
                <div className="MFOCalculatorTerms__label text_super-small-sm">{messages.terms.item.overpayment.label}</div>
                <div className="h4 h5-sm">
                  <MFOCalculatorPrice value={specialOfferOverpayment} />
                </div>
              </div>

              <div className="MFOCalculatorTerms__term MFOCalculatorTerms__term--economy">
                <div className="MFOCalculatorTerms__label MFOCalculatorTerms__label--accent text_super-small-sm">
                  {messages.terms.item.economy.label}
                </div>
                <div className="h3 h4-sm MFOCalculatorTerms__economyValue">
                  <MFOCalculatorPrice value={specialOfferEconomy} />
                  <div>
                    <BubblingTooltip
                      content={formatLocalizedString(messages.terms.item.economy.tooltip, {
                        Link: text => (
                          <CommonLink className="color_green underline underline_green" url={ECommonRoute.main}>
                            {text}
                          </CommonLink>
                        ),
                      })}
                      tooltipProps={{
                        touch: true,
                        interactive: true,
                        hideOnClick: false,
                        zIndex: 2,
                        className: 'MFOCalculatorTerms__tooltip-position',
                        placement: isMobile ? 'top-end' : 'top',
                      }}
                    >
                      <div className="MFOCalculatorTerms__questionIconWrapper">
                        <Icon className="MFOCalculatorTerms__questionIcon" name={EIconName.Question} size={EIconSize.M} />
                      </div>
                    </BubblingTooltip>
                  </div>
                </div>
              </div>

              <div className="MFOCalculatorTerms__label MFOCalculatorTerms__label--accent text_super-small-sm">
                {messages.terms.forAgro}
              </div>
            </div>
          </div>
        </div>

        <div className="MFOCalculatorTermsShowAllParams">
          <Button
            className="MFOCalculatorTermsShowAllParams__button"
            modifiers={['as-link']}
            iconPosition="right"
            onClick={onShowMobileContent}
          >
            {isShowMobileContent ? messages.terms.showAllParams : messages.terms.hideAllParams}
          </Button>
          <Icon
            className="MFOCalculatorTermsShowAllParams__icon"
            name={isShowMobileContent ? EIconName.ShevronDown : EIconName.ShevronUp}
            size={EIconSize.S}
          />
        </div>
      </div>
    </div>
  )
}
